import { Typography } from "@mui/material";
import { useState } from "react";
import { File } from "react-feather";

const VerificationFileViewer = ({
  verificationFileUrl
}: {
  verificationFileUrl: string;
}) => {
  const [isImageError, setIsImageError] = useState(false);

  return (
    <>
      {!isImageError ? (
        <img
          src={verificationFileUrl}
          style={{
            width: "500px",
            height: "300px",
            cursor: "pointer",
            borderRadius: "8px",
            objectFit: "contain"
          }}
          alt="Verification file"
          onError={() => setIsImageError(true)}
        />
      ) : (
        <a
          href={verificationFileUrl}
          target="_blank"
          rel="noreferrer"
          style={{ display: "flex", gap: 2 }}
        >
          <File />
          <Typography>View Uploaded File</Typography>
        </a>
      )}
    </>
  );
};

export default VerificationFileViewer;
