import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Container, Theme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import Zendesk from "react-zendesk";
import { Routes } from "./Routes";
import { Header } from "./components/Header";
import { LoadingSpinner } from "./components/LoadingSpinner";
import PWAPrompt from "./components/PWAPrompt";
import { Employer } from "./models";
import { EmployerBrand } from "./models/employer/employerBrand";
import { createExtraordinaryTheme, createHealthNowTheme } from "./utils/theme";
import { useConfig } from "./utils/useConfig";
import { useCustomQuery } from "./utils/useData";

function App() {
  const { config } = useConfig();
  const { login, getClaim, isAuthenticated, isLoading, error } = useKindeAuth();

  const [employerId, setEmployerId] = useState<string>();
  const [theme, setTheme] = useState<Theme>(createExtraordinaryTheme()); // Default theme

  // Have to query employer here as well, as we can't use useData 
  // due to getClaim not being defined until authenticated.
  const employer = useCustomQuery<Employer>({
    endpoint: `/employers/${employerId}`,
    options: {
      enabled: !!employerId
    }
  });

  const handleTheme = (employer: Employer | undefined) => {
    if (employer?.brand === EmployerBrand.HEALTHNOW) {
      const healthNowTheme = createHealthNowTheme();
      setTheme(healthNowTheme);
      updatePublicHtml("HealthNow", "/healthNowFavicon.png");
    } else if (employer?.brand === EmployerBrand.EXTRAORDINARY) {
      const extraordinaryTheme = createExtraordinaryTheme();
      setTheme(extraordinaryTheme);
      updatePublicHtml("Extraordinary", "/extraordinaryFavicon.png");
    }
  };

  const updatePublicHtml = (title: string, url: string): void => {
    document.title = title;

    const link: HTMLLinkElement | null =
      document.querySelector("link[rel*='icon']");

    const cacheBustedUrl = `${url}?v=${new Date().getTime()}`;

    if (link) {
      link.href = cacheBustedUrl;
    } else {
      const newLink: HTMLLinkElement = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = cacheBustedUrl;
      document.head.appendChild(newLink);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    const employerId = getClaim("external_org_id")?.value?.toString();
    setEmployerId(employerId);
  }, [getClaim, isAuthenticated]);

  useEffect(() => {
    if (employer.data) handleTheme(employer.data);
  }, [employer.data]);

  useEffect(() => {
    if (isAuthenticated || isLoading) return;
    login &&
      login({
        app_state: {
          redirectTo: window.location.pathname
        }
      });
  }, [isAuthenticated, login, isLoading]);

  if (isLoading || !isAuthenticated) return <LoadingSpinner />;

  if (error) return <p>{error}</p>;

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" sx={{ paddingBottom: 4 }}>
        {config?.ZENDESK_KEY && (
          <Zendesk defer zendeskKey={config?.ZENDESK_KEY} />
        )}
        <PWAPrompt />
        <Header />
        <Routes />
      </Container>
    </ThemeProvider>
  );
}

export default App;
