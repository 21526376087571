export async function fetchData(
  endpoint: string,
  getToken?: () => Promise<string | undefined>,
  queryString?: string,
  pagination?: {
    page: number;
    pageSize: number;
  }
) {

  try {
    const queryParams = [];
    if (queryString) {
      queryParams.push(queryString);
    }

    if (pagination) {
      if (pagination.page === 0 || pagination.page > 0) {
        queryParams.push(`page=${pagination.page}`);
      }
      if (pagination.pageSize > 0) {
        queryParams.push(`pageSize=${pagination.pageSize}`);
      }
    }

    const headers: HeadersInit = {
      "X-CLIENT-SOURCE": "MyPortal",
      Accept: "application/json"
    };

    if (getToken) {
      headers.Authorization = `Bearer ${await getToken()}`;
    }

    const finalUrl = `${endpoint}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`;
    const response = await fetch(finalUrl, {
      headers
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(`API request failed: ${error.message}`);
    } else {
      throw new Error(`API request failed with an unknown error`);
    }
  }
}

export async function callApi(
  endpoint: string,
  method: string,
  payload?: any | undefined,
  getToken?: () => Promise<string | undefined>,
  queryString?: string
) {
  try {
    const queryParams = [];
    if (queryString) {
      queryParams.push(queryString);
    }

    const isFormData = payload instanceof FormData;
    const headers: HeadersInit = {
      "X-CLIENT-SOURCE": "MyPortal"
    };

    if (!isFormData) {
      headers["Content-Type"] = "application/json";
    }

    if (getToken) {
      headers.Authorization = `Bearer ${await getToken()}`;
    }

    const options = {
      method,
      headers,
      body: isFormData ? payload : JSON.stringify(payload)
    };

    const finalUrl = `${endpoint}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`;
    return await fetch(finalUrl, options);
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(`API request failed: ${error.message}`);
    } else {
      throw new Error(`API request failed with an unknown error`);
    }
  }
}
