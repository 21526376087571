import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { UseQueryOptions, useQuery } from "react-query";
import {
  Card,
  Employee,
  EmployeeAccount,
  Employer,
  Feature,
  Pagination,
  Plan,
  ServiceSector,
  Transaction,
  TransactionStatus,
  TransactionType,
  UserInfo
} from "../models";
import { Communication } from "../models/communications/communication";
import { CommunicationPlatform } from "../models/communications/communicationPlatform";
import { fetchData } from "./fetchData";
import { useConfig } from "./useConfig";
import { CardOffering } from "../models/employer/cardOffering";

interface QueryOptions<TData> {
  endpoint: string;
  queryString?: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
  queryKey?: string;
  options?: UseQueryOptions<TData>;
}

export function useCustomQuery<TData>(options: QueryOptions<TData>) {
  const {
    endpoint,
    queryString,
    pagination,
    queryKey,
    options: customOptions
  } = options;
  const { getToken } = useKindeAuth();
  const { config } = useConfig();

  const query = useQuery<TData>(
    queryKey || endpoint,
    () =>
      fetchData(
        `${config?.API_URL}${endpoint}`,
        getToken,
        queryString,
        pagination
      ),
    {
      ...customOptions,
      enabled: customOptions?.enabled !== false && !!config,
      keepPreviousData: true
    }
  );

  return query;
}

export function useData() {
  const { getToken } = useKindeAuth();
  const { config } = useConfig();

  const { data: userInfoData, ...userInfo } = useQuery<UserInfo>(
    "/user-info",
    () => fetchData(`${config?.API_URL}/user-info`, getToken),
    {
      enabled: !!config
    }
  );

  const cards = useCustomQuery<Pagination<Card>>({
    endpoint: `/employers/${userInfoData?.employerId}/cards`,
    queryString: `employeeId=${userInfoData?.employeeId}`,
    options: {
      enabled: !!userInfoData
    }
  });

  const employer = useCustomQuery<Employer>({
    endpoint: `/employers/${userInfoData?.employerId}`,
    options: {
      enabled: !!userInfoData
    }
  });

  const employee = useCustomQuery<Employee>({
    endpoint: `/employers/${userInfoData?.employerId}/employees/${userInfoData?.employeeId}`,
    options: {
      enabled: !!userInfoData
    }
  });

  const accounts = useCustomQuery<Pagination<EmployeeAccount>>({
    endpoint: `/employers/${userInfoData?.employerId}/employees/${userInfoData?.employeeId}/accounts`,
    options: {
      enabled: !!userInfoData
    }
  });

  const plans = useCustomQuery<Pagination<Plan>>({
    endpoint: `/employers/${userInfoData?.employerId}/employees/${userInfoData?.employeeId}/plans`,
    options: {
      enabled: !!userInfoData
    }
  });

  const serviceSectors = useCustomQuery<Pagination<ServiceSector>>({
    endpoint: `/service-sectors`,
    queryString: `employerid=${userInfoData?.employerId}`,
    options: {
      enabled: !!userInfoData
    }
  });

  const transactions = useCustomQuery<Pagination<Transaction>>({
    endpoint: `/transactions`,
    queryString: `employerId=${userInfoData?.employerId}&employeeId=${
      userInfoData?.employeeId
    }&status=${TransactionStatus.PENDING.toString()}&status=${TransactionStatus.APPROVED.toString()}&status=${TransactionStatus.DECLINED.toString()}&type=${
      TransactionType.PURCHASE
    }&type=${TransactionType.PAYMENT}&type=${TransactionType.REFUND}
    `,
    pagination: {
      page: 0,
      pageSize: 2000
    },
    options: {
      enabled: !!userInfoData
    }
  });

  const features = useCustomQuery<Feature[]>({
    endpoint: `/features`
  });

  const offerings = useCustomQuery<CardOffering[]>({
    endpoint: `/card-offerings?employerId=${userInfoData?.employerId}`
  })

  const communications = useCustomQuery<Pagination<Communication>>({
    endpoint: `/communications`,
    queryString: `employerId=${userInfoData?.employerId}&employeeId=${userInfoData?.employeeId}&platform=${CommunicationPlatform.EMPLOYER_WEB}`,
    options: {
      enabled: !!userInfoData
    }
  });

  async function getCardStatus(cardId: string) {
    return await fetchData(
      `${config?.API_URL}/employers/${userInfoData?.employerId}/cards/${cardId}/status`,
      getToken
    );
  }

  function refetch() {
    cards.refetch();
    plans.refetch();
    transactions.refetch();
  }

  return {
    cards,
    employer,
    employee,
    accounts,
    plans,
    serviceSectors,
    transactions,
    refetch,
    userInfoData,
    userInfo,
    features,
    communications,
    offerings,
    getCardStatus
  };
}
